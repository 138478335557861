import { Clients } from "components/Client/Clients";
import { Project } from "components/Project/Project";
import { Projects } from "components/Project/Projects";
import AuthentifiedLayout from "layout/AuthentifiedLayout";
import UnAuthentifiedLayout from "layout/UnAuthentifiedLayout";

import { Navigate } from "react-router-dom";
import { DashboardView } from "views/DashboardView";
import { DefaultView } from "views/DefaultView";

export const routes = (isLogged: boolean | null | undefined) => {
    return [
        {
            path: "/",
            element: <Navigate to="/loggedout" />,
        },
        {
            path: "/loggedout",
            element: !isLogged ? <UnAuthentifiedLayout /> : <Navigate to="/dashboard" />,
            children: [
                {
                    path: "",
                    element: <DefaultView />,
                },
            ],
        },
        {
            path: "/dashboard",
            element: isLogged ? <AuthentifiedLayout /> : <Navigate to="/loggedout" />,
            children: [
                {
                    path: "",
                    element: isLogged ? (
                        <DashboardView componentToRender={<Projects />} headerTitle="Projets" />
                    ) : (
                        <Navigate to="/loggedout" />
                    ),
                },
                {
                    path: "/dashboard/clients",
                    element: isLogged ? (
                        <DashboardView componentToRender={<Clients />} headerTitle="Clients" />
                    ) : (
                        <Navigate to="/loggedout" />
                    ),
                },
                {
                    path: "/dashboard/project/:id",
                    element: isLogged ? (
                        <DashboardView componentToRender={<Project />} headerTitle="Projet" />
                    ) : (
                        <Navigate to="/loggedout" />
                    ),
                },
            ],
        },
    ];
};

export default routes;
