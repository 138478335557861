import axiosClient from "./client";

export const updateProject = (project: any) => {
    return axiosClient.put(`/app/project/${project.id}`, { ...project }).then((response) => response);
};

export const addProject = (project: any) => {
    return axiosClient.post(`/app/project`, { ...project }).then((response) => response);
};

export const deleteProject = (id: number) => {
    return axiosClient.delete(`/app/project/${id}`).then((response) => response);
};

export const getProjects = () => {
    return axiosClient.get(`/app/projects`).then((response) => response);
};

export const getProject = (id: number) => {
    return axiosClient.get(`/app/project/${id}`).then((response) => response);
};
