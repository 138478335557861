import {
    Box,
    Button,
    Container,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    OutlinedInput,
    Paper,
    Select,
    SelectChangeEvent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Client } from "../../types/Client";
import AddIcon from "@mui/icons-material/Add";
import { CreateClient } from "./CreateClient";
import { deleteClient, getClients } from "api/clients";
import { Edit } from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { UpdateClient } from "./UpdateClient";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const Clients: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [openUpdateModal, setOpenUpdateModal] = React.useState<[boolean, number]>([false, 0]);
    const [openDeleteModal, setOpenDeleteModal] = React.useState<[boolean, number]>([false, 0]);
    const [clients, setClients] = useState<Client[]>();
    const [filteredClients, setFilteredClients] = useState<Client[]>();
    const [filter, setFilter] = useState<Client["agency"] | "all">("all");

    const fetchClients = () => {
        getClients().then((data) => {
            setClients(data.data);
        });
    };

    const handleChange = (event: SelectChangeEvent<any>) => {
        const {
            target: { value },
        } = event;
        setFilter(value);
    };

    useEffect(() => {
        if (!clients) {
            setFilteredClients(clients);
        }
    }, [clients]);

    useEffect(() => {
        console.log('filter', filter)
        if (!clients) return;
        if (filter === "all") {
            setFilteredClients(clients);
            return;
        }
        setFilteredClients(clients.filter((c: Client) => c.agency === filter));
    }, [filter, clients]);



    const handleDeleteClient = (id: number) => {
        deleteClient(id).then((data) => {
            console.log("data", data);
            fetchClients();
            setOpenDeleteModal([false, 0]);
        });
    };

    useEffect(() => {
        fetchClients();
    }, []);

    return (
        <Container>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" align="left" sx={{ pb: 3 }}>
                    Clients
                </Typography>
                <Container sx={{ width: "auto", display: "flex", alignItems: "center", p: "0px !important" }}>
                    <FormControl sx={{ width: "200px", mr: 2 }}>
                        <InputLabel id="agence-label">Agence</InputLabel>
                        <Select
                            labelId="agence-label"
                            id="agence"
                            value={filter}
                            onChange={handleChange}
                            input={<OutlinedInput label="Client de" />}
                            MenuProps={MenuProps}
                        >
                            <MenuItem key="all" value="all" sx={{ textAlign: 'left' }}>
                                Toutes
                            </MenuItem>
                            <MenuItem key="Getup Agency" value="Getup Agency" sx={{ textAlign: 'left' }}>
                                Getup Agency
                            </MenuItem>
                            <MenuItem key="Héhocom" value="Héhocom" sx={{ textAlign: 'left' }}>
                                Héhocom
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Button onClick={() => setOpen(true)} variant="contained" endIcon={<AddIcon />}>
                        Créer un client{" "}
                    </Button>
                </Container>
            </Stack>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Client</TableCell>
                            <TableCell>Adresse e-mail</TableCell>
                            <TableCell>Entreprise</TableCell>
                            <TableCell>N° de téléphone</TableCell>
                            <TableCell>Adresse</TableCell>
                            <TableCell>Agence</TableCell>
                            <TableCell>SIRET</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredClients &&
                            filteredClients.map((client: Client) => (
                                <>
                                    <TableRow hover key={client.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            {client.id}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {client.firstname} {client.lastname}
                                        </TableCell>
                                        <TableCell>
                                            <a href={`mailto:${client.email}`}>{client.email}</a>
                                        </TableCell>
                                        <TableCell>{client.company}</TableCell>
                                        <TableCell>
                                            <a href={`tel:${client.phone}`}>{client.phone}</a>
                                        </TableCell>
                                        <TableCell>
                                            {client.zipcode} - {client.town}, {client.address}
                                        </TableCell>
                                        <TableCell>{client.agency}</TableCell>
                                        <TableCell>{client.siret}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => setOpenUpdateModal([true, client.id])}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={() => setOpenDeleteModal([true, client.id])}>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <Modal
                                        open={openDeleteModal[0]}
                                        onClose={() => setOpenDeleteModal([false, 0])}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <Typography variant="h6" align="center">
                                                Voulez-vous supprimer ce client ?
                                            </Typography>
                                            <Typography variant="h6" align="center">
                                                {client.firstname} {client.lastname} ({client.company})
                                            </Typography>
                                            <Container
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {filteredClients.find((c) => c.id === openDeleteModal[1]) && (
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        sx={{ m: 2, mb: 0 }}
                                                        onClick={() =>
                                                            handleDeleteClient(filteredClients.find((c) => c.id === openDeleteModal[1])!.id)
                                                        }
                                                    >
                                                        Oui
                                                    </Button>
                                                )}
                                                <Button
                                                    variant="contained"
                                                    sx={{ m: 2, mb: 0 }}
                                                    onClick={() => setOpenDeleteModal([false, 0])}
                                                >
                                                    Non
                                                </Button>
                                            </Container>
                                        </Box>
                                    </Modal>
                                    <Modal
                                        open={openUpdateModal[0]}
                                        onClose={() => setOpenUpdateModal([false, 0])}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style}>
                                            <Typography variant="h6" align="center">
                                                Modifier le projet
                                            </Typography>
                                            {filteredClients.find((c) => c.id === openUpdateModal[1]) && (
                                                <UpdateClient
                                                    setOpen={() => setOpenUpdateModal([false, 0])}
                                                    refetch={fetchClients}
                                                    clientToUpdate={filteredClients.find((c) => c.id === openUpdateModal[1])!}
                                                />
                                            )}
                                        </Box>
                                    </Modal>
                                </>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography variant="h6" align="center">
                        Créer un client
                    </Typography>
                    <CreateClient setOpen={setOpen} refetch={fetchClients} />
                </Box>
            </Modal>
        </Container>
    );
};
