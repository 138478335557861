import { Button, Container, FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Client } from "../../types/Client";
import { addClient, updateClient as updateClientApi } from "api/clients";

export type UpdateClientProps = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
    clientToUpdate: Client;
};

export const UpdateClient: React.FC<UpdateClientProps> = ({ setOpen, refetch, clientToUpdate }) => {
    const [client, setClient] = useState<Client>(clientToUpdate);

    const updateClient = (key: string, val: any) => {
        setClient((prevClient) => ({
            ...prevClient,
            [key]: val,
        }));
    };

    const formHasError = (client: Partial<Client>) => Object.values(client).reduce((acc, val) => acc || !val || !`${val}`.length, false);

    const submit = () => {
        if (formHasError(client)) {
            return;
        }

        updateClientApi(client.id, client).then((data) => {
            setOpen(false);
            refetch();
        });
    };

    return (
        <Container>
            <FormControl sx={{ m: 1, mb: 2, width: "15ch" }}>
                <TextField
                    label="Prénom"
                    variant="standard"
                    value={client.firstname}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        updateClient("firstname", event.target.value);
                    }}
                />
            </FormControl>

            <FormControl sx={{ m: 1, mb: 2, width: "15ch" }}>
                <TextField
                    label="Nom de famille"
                    variant="standard"
                    value={client.lastname}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        updateClient("lastname", event.target.value);
                    }}
                />
            </FormControl>

            <FormControl fullWidth sx={{ m: 1, mb: 2 }}>
                <TextField
                    label="Adresse e-mail"
                    type="email"
                    variant="standard"
                    value={client.email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        updateClient("email", event.target.value);
                    }}
                />
            </FormControl>

            <FormControl sx={{ m: 1, mb: 2, width: "15ch" }}>
                <TextField
                    label="Entreprise"
                    variant="standard"
                    value={client.company}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        updateClient("company", event.target.value);
                    }}
                />
            </FormControl>

            <FormControl sx={{ m: 1, mb: 2, width: "15ch" }}>
                <TextField
                    label="N° de téléphone"
                    type="phone"
                    variant="standard"
                    value={client.phone}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        updateClient("phone", event.target.value);
                    }}
                />
            </FormControl>

            <FormControl sx={{ m: 1, mb: 2, width: "15ch" }}>
                <TextField
                    label="Zipcode"
                    variant="standard"
                    value={client.zipcode}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        updateClient("zipcode", event.target.value);
                    }}
                />
            </FormControl>

            <FormControl sx={{ m: 1, mb: 2, width: "15ch" }}>
                <TextField
                    label="Ville"
                    variant="standard"
                    value={client.town}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        updateClient("town", event.target.value);
                    }}
                />
            </FormControl>

            <FormControl fullWidth sx={{ m: 1, mb: 2 }}>
                <TextField
                    label="Adresse"
                    variant="standard"
                    value={client.address}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        updateClient("address", event.target.value);
                    }}
                />
            </FormControl>

            <FormControl fullWidth sx={{ m: 1, mb: 2 }}>
                <TextField
                    label="SIRET"
                    variant="standard"
                    value={client.siret}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        updateClient("siret", event.target.value);
                    }}
                />
            </FormControl>

            <Button variant="contained" onClick={submit}>
                Modifier le client
            </Button>
        </Container>
    );
};

{
    /* 
    {Object.keys(client).map((key: string) => (
        <TextField key={key} label={key} variant="standard" value={client[key as keyof Client]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateClient(key, event.target.value);
            }}
        />
    ))} 
*/
}
