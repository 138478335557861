import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./auth/auth.slice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    storage,
};

const rootReducer = combineReducers({
    hehogetupauthent: authSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
});

export type AppStore = typeof store;

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
