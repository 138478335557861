import React from "react";

import "./App.css";
import "@fontsource/roboto/400.css";

import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { useSelector } from "react-redux";
import { getIsLogged } from "features/auth/auth.slice";

const App: React.FC = () => {
    const isLogged = useSelector(getIsLogged);
    const routing = useRoutes(routes(isLogged));

    return (
        <div className="App">
            <>{routing}</>
        </div>
    );
};

export default App;
