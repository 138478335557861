import axios from "axios";
import { store } from "features/store";

/* Default axios object */
const axiosClient = axios.create();
axiosClient.defaults.baseURL = "https://hehocom-getup-api.getup.agency";
axiosClient.defaults.timeout = 20000;

axiosClient.interceptors.request.use(
    async (config) => {
        const jwt = store.getState().hehogetupauthent.jwt;
        config.headers.jwt = jwt;
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

export default axiosClient;
