import { Button, Container, FormControl, Paper, TextField, Typography } from "@mui/material";
import { login as apiLogin } from "api/auth";
import { login } from "features/auth/auth.slice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const LoginForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [payload, setPayload] = useState({
        email: "",
        password: "",
    });

    const updatePayload = (key: string, val: string) => {
        setPayload((prevPayload) => ({
            ...prevPayload,
            [key]: val,
        }));
    };

    const formHasError = (data: any) => Object.values(data).reduce((acc, val) => acc || !val || !`${val}`.length, false);

    const submit = (e: any) => {
        e.preventDefault();
        if (formHasError(payload)) {
            console.log("errorrrrr");
            return;
        }

        apiLogin(payload)
            .then((data) => {
                dispatch(login({ jwt: data.data.jwt }));
                navigate("/dashboard");
            })
            .catch((data) => {
                console.log("data", data);
            });
    };

    return (
        <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>
            <Paper elevation={3} sx={{ width: "350px" }}>
                <Container sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Typography variant="h6" noWrap component="div" sx={{ p: 2, pb: 0 }}>
                        Hehocom x Getup
                    </Typography>

                    <form>
                        <FormControl fullWidth sx={{ mb: 0 }}>
                            <TextField
                                label="Adresse e-mail"
                                type="email"
                                variant="standard"
                                value={payload.email}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    updatePayload("email", event.target.value);
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <TextField
                                label="Mot de passe"
                                variant="standard"
                                type="password"
                                value={payload.password}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    updatePayload("password", event.target.value);
                                }}
                            />
                        </FormControl>

                        <Button type="submit" variant="contained" onClick={submit} sx={{ mb: 2 }}>
                            Se connecter
                        </Button>
                    </form>
                </Container>
            </Paper>
        </Container>
    );
};
