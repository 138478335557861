import { Menu } from "components/Menu/Menu";
import React from "react";
import "./DashboardView.scss";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { logout } from "features/auth/auth.slice";

export type DashboardViewProps = {
    componentToRender: React.ReactNode;
    headerTitle: string;
};

export const DashboardView: React.FC<DashboardViewProps> = ({ componentToRender, headerTitle }) => {
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
    };

    return (
        <div className="dashboard">
            <AppBar position="fixed">
                <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" noWrap component="div">
                        Hehocom x Getup
                    </Typography>
                    <IconButton onClick={handleLogout}>
                        <Logout sx={{ color: "#fff" }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className="dashboard-view">
                {/* <Header nbNotifications={nbNotifications} setNbNotifications={setNbNotifications} title={headerTitle} /> */}
                <Menu />
                <div className="component-rendered">{componentToRender}</div>
            </div>
        </div>
    );
};
