import axiosClient from "./client";

export const getMissions = () => {
    return axiosClient.get(`/app/missions`).then((response) => response);
};

export const addMission = (mission: any) => {
    return axiosClient.post(`/app/mission`, mission).then((response) => response);
};

export const deleteMission = (id: number) => {
    return axiosClient.delete(`/app/mission/${id}`).then((response) => response);
};

// export const updateClient = (id: number, project: any) => {
//     return axiosClient.put(`/app/client/${id}`, { ...project }).then((response) => response);
// };
