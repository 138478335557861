import axiosClient from "./client";

export const getClients = () => {
    return axiosClient.get(`/app/clients`).then((response) => response);
};

export const addClient = (client: any) => {
    return axiosClient.post(`/app/client`, client).then((response) => response);
};

export const updateClient = (id: number, client: any) => {
    return axiosClient.put(`/app/client/${id}`, client).then((response) => response);
};

export const deleteClient = (id: number) => {
    return axiosClient.delete(`/app/client/${id}`).then((response) => response);
};
