import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddIcon from "@mui/icons-material/Add";
import { Project } from "../../types/Project";
import { Mission } from "../../types/Mission";
import { Button, Container, Modal, Stack } from "@mui/material";
import { CreateProject } from "./CreateProject";
import { useNavigate } from "react-router-dom";
import { deleteProject as deleteProjectApi, getProjects } from "api/projects";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { Edit } from "@mui/icons-material";
import { UpdateProject } from "./UpdateProject";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function Row(props: { project: Project; refetch: () => void }) {
    const { project, refetch } = props;
    const navigate = useNavigate();

    const [open, setOpen] = React.useState<boolean>(false);
    const [openUpdateModal, setOpenUpdateModal] = React.useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);

    const deleteProject = (id: number) => {
        deleteProjectApi(id).then((data) => {
            setOpenDeleteModal(false);
            refetch();
        });
    };

    return (
        <React.Fragment>
            <TableRow hover sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {project.id}
                </TableCell>
                <TableCell component="th" scope="row">
                    <p className="link" onClick={() => navigate(`/dashboard/project/${project.id}`)}>
                        {project.name}
                    </p>
                </TableCell>
                <TableCell>{project.price} € HT</TableCell>
                {project.client && (
                    <TableCell>
                        {project.client.firstname} {project.client.lastname} ({project.client.company})
                    </TableCell>
                )}
                {!project.client && <TableCell>Pas de client associé</TableCell>}

                {project.start_at && <TableCell>{moment(project.start_at).format("DD MMM YYYY")}</TableCell>}
                {!project.start_at && (
                    <TableCell>
                        <CloseIcon color="error" />
                    </TableCell>
                )}

                {project.end_at && <TableCell>{moment(project.end_at).format("DD MMM YYYY")}</TableCell>}

                {!project.end_at && (
                    <TableCell>
                        <CloseIcon color="error" />
                    </TableCell>
                )}

                <TableCell>{moment(project.created_at).format("DD MMM YYYY")}</TableCell>
                <TableCell>
                    <IconButton onClick={() => setOpenUpdateModal(true)}>
                        <Edit />
                    </IconButton>
                    <IconButton onClick={() => setOpenDeleteModal(true)}>
                        <DeleteForeverIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Missions
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Mission</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Tag</TableCell>
                                        <TableCell>Rapporteur</TableCell>
                                        <TableCell>Créé le</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {project.missions &&
                                        project.missions.map((mission: Mission) => (
                                            <TableRow hover key={mission.id}>
                                                <TableCell component="th" scope="row">
                                                    {mission.id}
                                                </TableCell>
                                                <TableCell>{mission.name}</TableCell>
                                                <TableCell sx={{ maxWidth: "400px !important" }}>{mission.description}</TableCell>
                                                <TableCell>{mission.tags.length ? mission.tags[0].label : ""}</TableCell>
                                                <TableCell>
                                                    {mission.user.firstname} {mission.user.lastname} ({mission.user.company})
                                                </TableCell>
                                                <TableCell>{moment(mission.created_at).format("DD MMM YYYY")}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

            <Modal
                open={openUpdateModal}
                onClose={() => setOpenUpdateModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography variant="h6" align="center">
                        Modifier le projet
                    </Typography>
                    <UpdateProject setOpen={setOpenUpdateModal} refetch={refetch} projectToUpdate={project} />
                </Box>
            </Modal>

            <Modal
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography variant="h6" align="center">
                        Voulez-vous supprimer ce projet ?
                    </Typography>
                    <Container sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <Button variant="contained" color="error" sx={{ m: 2, mb: 0 }} onClick={() => deleteProject(project.id)}>
                            Oui
                        </Button>
                        <Button variant="contained" sx={{ m: 2, mb: 0 }} onClick={() => setOpenDeleteModal(false)}>
                            Non
                        </Button>
                    </Container>
                </Box>
            </Modal>
        </React.Fragment>
    );
}

export const Projects = () => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [projects, setProjects] = React.useState<Project[]>();

    const fetchProjects = () => {
        getProjects().then((data) => {
            setProjects(data.data);
        });
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    return (
        <Container>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4" align="left" sx={{ pb: 3 }}>
                    Projets
                </Typography>
                <Button onClick={() => setOpen(true)} variant="contained" endIcon={<AddIcon />}>
                    Créer un projet
                </Button>
            </Stack>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>ID</TableCell>
                            <TableCell>Projet</TableCell>
                            <TableCell>Prix</TableCell>
                            <TableCell>Client</TableCell>
                            <TableCell>Date de début</TableCell>
                            <TableCell>Date de fin</TableCell>
                            <TableCell>Créé le</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projects && projects.map((project) => <Row key={project.name} project={project} refetch={fetchProjects} />)}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography variant="h6" align="center">
                        Créer un projet
                    </Typography>
                    <CreateProject setOpen={setOpen} refetch={fetchProjects} />
                </Box>
            </Modal>
        </Container>
    );
};
