// import { LoginForm } from 'components/index';
// import './DefaultView.scss';
import { LoginForm } from "components/Login/LoginForm";
import React from "react";

export type DefaultViewProps = {
    title?: string;
};

export const DefaultView: React.FC<DefaultViewProps> = ({ title }) => {
    return (
        <div className="default-view">
            <LoginForm />
        </div>
    );
};
