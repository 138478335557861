import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "features/store";

export type AuthState = {
    isLogged: boolean;
    jwt: string | null;
};

const initialAuthState: AuthState = {
    isLogged: false,
    jwt: null,
};

export const authSlice = createSlice({
    name: "hehogetupauthent",
    initialState: initialAuthState,
    reducers: {
        login: (state, action: PayloadAction<{ jwt: string | null }>) => {
            state.isLogged = true;
            state.jwt = action.payload.jwt;
        },
        logout: (state) => {
            state.isLogged = false;
            state.jwt = null;
        },
    },
});
export const getIsLogged = (state: RootState) => state.hehogetupauthent.isLogged;
export const getJwtToken = (state: RootState) => state.hehogetupauthent.jwt;
export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
