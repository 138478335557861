import { Button, Container, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { useState } from "react";
import { Client } from "../../types/Client";
import { addClient } from "api/clients";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export type CreateClientProps = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
};

export const CreateClient: React.FC<CreateClientProps> = ({ setOpen, refetch }) => {
    const [client, setClient] = useState<Partial<Client>>({
        firstname: "",
        lastname: "",
        company: "",
        email: "",
        phone: "",
        address: "",
        zipcode: "",
        town: "",
        siret: "",
    });

    const updateClient = (key: string, val: any) => {
        setClient((prevClient) => ({
            ...prevClient,
            [key]: val,
        }));
    };

    const handleChange = (event: SelectChangeEvent<any>) => {
        const {
            target: { value },
        } = event;
        setClient((prevClient) => ({
            ...prevClient,
            agency: value,
        }));
    };

    const formHasError = (client: Partial<Client>) =>
        Object.entries(client).reduce((acc, [key, val]) => (key === "siret" ? acc || false : acc || !val || !`${val}`.length), false);

    const submit = (e: any) => {
        e.preventDefault();
        if (formHasError(client)) {
            return;
        }

        addClient(client).then((data) => {
            setOpen(false);
            refetch();
        });
    };

    return (
        <Container>
            <form>
                <FormControl sx={{ m: 1, mb: 2, width: "15ch" }}>
                    <TextField
                        label="Prénom"
                        required
                        variant="standard"
                        value={client.firstname}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("firstname", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, mb: 2, width: "15ch" }}>
                    <TextField
                        label="Nom de famille"
                        required
                        variant="standard"
                        value={client.lastname}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("lastname", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, mb: 2 }}>
                    <TextField
                        label="Adresse e-mail"
                        required
                        type="email"
                        variant="standard"
                        value={client.email}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("email", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, mb: 2, width: "15ch" }}>
                    <TextField
                        label="Entreprise"
                        required
                        variant="standard"
                        value={client.company}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("company", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, mb: 2, width: "15ch" }}>
                    <TextField
                        label="N° de téléphone"
                        required
                        type="phone"
                        variant="standard"
                        value={client.phone}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("phone", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, mb: 2, width: "15ch" }}>
                    <TextField
                        label="Zipcode"
                        required
                        variant="standard"
                        value={client.zipcode}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("zipcode", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, mb: 2, width: "15ch" }}>
                    <TextField
                        label="Ville"
                        required
                        variant="standard"
                        value={client.town}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("town", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, mb: 2 }}>
                    <TextField
                        label="Adresse"
                        required
                        variant="standard"
                        value={client.address}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("address", event.target.value);
                        }}
                    />
                </FormControl>

                <FormControl sx={{ m: 1, mb: 2, width: "100%" }}>
                    <InputLabel id="demo-name-label">Client de</InputLabel>
                    <Select
                        labelId="demo-name-label"
                        id="demo-name"
                        required
                        value={client.agency}
                        onChange={handleChange}
                        input={<OutlinedInput label="Client de" />}
                        MenuProps={MenuProps}
                    >
                        <MenuItem key="Getup Agency" value="Getup Agency">
                            Getup Agency
                        </MenuItem>
                        <MenuItem key="Héhocom" value="Héhocom">
                            Héhocom
                        </MenuItem>
                    </Select>
                </FormControl>

                <FormControl fullWidth sx={{ m: 1, mb: 2 }}>
                    <TextField
                        label="SIRET"
                        variant="standard"
                        value={client.siret}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            updateClient("siret", event.target.value);
                        }}
                    />
                </FormControl>

                <Button type="submit" variant="contained" onClick={submit}>
                    Créer le client
                </Button>
            </form>
        </Container>
    );
};

{
    /* 
    {Object.keys(client).map((key: string) => (
        <TextField key={key} label={key} variant="standard" value={client[key as keyof Client]}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateClient(key, event.target.value);
            }}
        />
    ))} 
*/
}
