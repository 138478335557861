import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useNavigate } from "react-router-dom";

export const Menu = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ width: 250, height: "calc(100vh - 64px)" }} role="presentation">
            <List>
                <ListItem key="Projets" disablePadding onClick={() => navigate("/dashboard")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Projets" />
                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem key="Clients" disablePadding onClick={() => navigate("/dashboard/clients")}>
                    <ListItemButton>
                        <ListItemIcon>
                            <MailIcon />
                        </ListItemIcon>
                        <ListItemText primary="Clients" />
                    </ListItemButton>
                </ListItem>
                <Divider />
            </List>
        </Box>
    );
};
