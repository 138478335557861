import {
    Box,
    Button,
    Chip,
    Container,
    IconButton,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Mission } from "types/Mission";
import { Project as ProjectType } from "types/Project";
import { Tag } from "types/Tag";

import moment from "moment";

import AddIcon from "@mui/icons-material/Add";
import { CreateMission } from "components/Mission/CreateMission";
import { getTags } from "api/tags";
import { getProject } from "api/projects";
import { Edit } from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { deleteMission } from "api/missions";
import { UpdateMission } from "components/Mission/UpdateMission";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export const Project: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [open, setOpen] = useState<boolean>(false);
    const [openUpdateModal, setOpenUpdateModal] = React.useState<[boolean, number]>([false, 0]);
    const [openDeleteModal, setOpenDeleteModal] = React.useState<[boolean, number]>([false, 0]);

    const [allTags, setAllTags] = useState<Tag[]>();
    const [project, setProject] = useState<ProjectType>();

    const projectId = id ? parseInt(id) : 0;

    const handleDeleteMission = (id: number) => {
        deleteMission(id).then((data) => {
            console.log("data", data);
            fetchProject();
            setOpenDeleteModal([false, 0]);
        });
    };

    const fetchProject = () => {
        getProject(projectId).then((data) => {
            console.log("data", data);
            setProject(data.data);
        });
    };

    useEffect(() => {
        getTags().then((data) => {
            setAllTags(data.data);
        });
        fetchProject();
    }, []);

    return (
        <div>
            {project && (
                <div className="project-detail">
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        {project.name}
                    </Typography>
                    <Typography variant="body1">Créé le {moment(project.created_at).format("DD MMM YYYY HH[h]ss")}</Typography>
                    <Typography variant="body1">
                        Par {project.user.firstname} {project.user.lastname} ({project.user.company})
                    </Typography>

                    <Container
                        sx={{
                            maxWidth: "unset !important",
                            p: "0px !important",
                            mb: 3,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button onClick={() => setOpen(true)} variant="contained" endIcon={<AddIcon />}>
                            Créer une mission
                        </Button>
                    </Container>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Nom</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Tags</TableCell>
                                    <TableCell>Date de début</TableCell>
                                    <TableCell>Date de fin</TableCell>
                                    <TableCell>Créé le</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {project.missions.map((mission: Mission) => (
                                    <>
                                        <TableRow hover key={mission.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                {mission.id}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {mission.name}
                                            </TableCell>
                                            <TableCell sx={{ maxWidth: "600px" }}>{mission.description}</TableCell>
                                            <TableCell>
                                                {mission.tags.map((tag: Tag) => (
                                                    <Chip sx={{ mr: 1 }} label={tag.label} size="small" color="success"></Chip>
                                                ))}
                                            </TableCell>
                                            {mission.start_at && <TableCell>{moment(mission.start_at).format("DD MMM YYYY")}</TableCell>}
                                            {!mission.start_at && (
                                                <TableCell>
                                                    <CloseIcon color="error" />
                                                </TableCell>
                                            )}

                                            {mission.end_at && <TableCell>{moment(mission.end_at).format("DD MMM YYYY")}</TableCell>}

                                            {!mission.end_at && (
                                                <TableCell>
                                                    <CloseIcon color="error" />
                                                </TableCell>
                                            )}
                                            <TableCell>{moment(mission.created_at).format("DD MMM YYYY")}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => setOpenUpdateModal([true, mission.id])}>
                                                    <Edit />
                                                </IconButton>
                                                <IconButton onClick={() => setOpenDeleteModal([true, mission.id])}>
                                                    <DeleteForeverIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        <Modal
                                            open={openDeleteModal[0]}
                                            onClose={() => setOpenDeleteModal([false, 0])}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box sx={style}>
                                                <Typography variant="h6" align="center">
                                                    Voulez-vous supprimer cette mission ?
                                                </Typography>
                                                <Typography variant="body1" sx={{ m: 1 }} align="center">
                                                    {mission.name}
                                                </Typography>
                                                <Typography variant="body2">{mission.description}</Typography>
                                                <Container
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {project.missions.find((m) => m.id === openDeleteModal[1]) && (
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            sx={{ m: 2, mb: 0 }}
                                                            onClick={() =>
                                                                handleDeleteMission(
                                                                    project.missions.find((m) => m.id === openDeleteModal[1])!.id
                                                                )
                                                            }
                                                        >
                                                            Oui
                                                        </Button>
                                                    )}
                                                    <Button
                                                        variant="contained"
                                                        sx={{ m: 2, mb: 0 }}
                                                        onClick={() => setOpenDeleteModal([false, 0])}
                                                    >
                                                        Non
                                                    </Button>
                                                </Container>
                                            </Box>
                                        </Modal>
                                        <Modal
                                            open={openUpdateModal[0]}
                                            onClose={() => setOpenUpdateModal([false, 0])}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box sx={style}>
                                                <Typography variant="h6" align="center">
                                                    Modifier le projet
                                                </Typography>
                                                {project.missions.find((m) => m.id === openUpdateModal[1]) && allTags && (
                                                    <UpdateMission
                                                        setOpen={() => setOpenUpdateModal([false, 0])}
                                                        projectId={projectId}
                                                        refetch={fetchProject}
                                                        tags={allTags}
                                                        missionToUpdate={project.missions.find((m) => m.id === openUpdateModal[1])!}
                                                    />
                                                )}
                                            </Box>
                                        </Modal>
                                    </>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Modal
                        open={open}
                        onClose={() => setOpen(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography variant="h6" align="center">
                                Créer une mission
                            </Typography>
                            {allTags && <CreateMission projectId={projectId} setOpen={setOpen} refetch={fetchProject} tags={allTags} />}
                        </Box>
                    </Modal>
                </div>
            )}
        </div>
    );
};
